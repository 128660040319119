import React from "react";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import ExperienceTabItem from "./ExperienceTabItem";
import { color } from "framer-motion";

import CssFilterConverter from 'css-filter-converter';
import Image from "../../components/Image";


function ExperienceTabs({ pagesSectionData }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeIndex, setIndex] = React.useState(0);
  const [hoverStyle, setHoverStyle] = React.useState({});

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  const tabsData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "TABS"
      )[0] ?? null
  );
  const subSectionData = tabsData?.[0]?.subSectionData;
  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
        tabsArray={subSectionData}
      >
        {subSectionData?.map((item, index) => {
          let bgcolor =
            item.elements.filter(
              (name) => name.field_title_code === "COLORCODE"
            )[0]?.data ?? null;
          const root = document.documentElement;

          // root.style.setProperty('--light-see-green', bgcolor);

          const color = {
            background: bgcolor,

            borderTopColor: `${bgcolor} !important`,
          };
          
          function handelHover(index) {
            const style = {
              border: `1px solid ${bgcolor}`,
            };
            setHoverStyle(style);
            setIndex(index);
          }
          function handleMouseLeave() {
            setHoverStyle({});
            setIndex(activeTab); // Reset active index to the currently active tab
          }
          if (activeTab === index) {
            root.style.setProperty("--light-see-green", bgcolor);
          }
          const filteredStyle = CssFilterConverter.hexToFilter(bgcolor).color;
          
          return (
            
            <Tab
              onMouseEnter={() => handelHover(index)}
              onMouseLeave={handleMouseLeave}
              key={index}
              className={`${item.tabNavBrand} item ${
                activeTab === index
                  ? "rts___tab rts___btn diving-nav item active text-uppercase d-flex align-items-center rts___tab___selected "
                  : "text-uppercase d-flex align-items-center"
              }`}
              style={{
                ...(index === activeIndex ? hoverStyle : ""),
                ...(activeTab === index ? color : ""),
              }}
            >
              <span className="tab-feature-img d-flex rounded-circle overflow-hidden"
            style={{
                ...(activeTab === index ? { backgroundColor: "white" } : {backgroundColor: bgcolor}),
              }}
            
            >
                <Image
                  src={
                    item.elements.filter(
                      (image) => image.field_title_code === "ICON"
                    )[0]?.data ?? null
                  }
                  
                  style={{
                    ...(activeTab === index ? {filter : filteredStyle }  : ""),
                  }}
                
                  alt=""
                  title=""
                />
              </span>
              <span className="tab-name text-uppercase" 
              style={{
                     ...(index === activeIndex ? {color:bgcolor} : ""),
                  }}
                  >
                {" "}
                {item.elements.filter(
                  (name) => name.field_title_code === "NAME"
                )[0]?.data ?? null}
              </span>
            </Tab>
          );
        })}
      </Tabs>

      {subSectionData?.map((screen, index) => (
        <TabScreen
          key={index}
          // key={+screen.page_sub_section_id}
          activeTab={activeTab}
          index={index}
        >
          <ExperienceTabItem
            //  tabBrand={screen.tabBrand}
            colorCode={
              screen.elements.filter(
                (item) => item.field_title_code === "COLORCODE"
              )[0]?.data ?? null
            }
            tabMainHeading={
              screen.elements.filter(
                (item) => item.field_title_code === "TITLE"
              )[0]?.data ?? null
            }
            tabAboutPara={
              screen.elements.filter(
                (item) => item.field_title_code === "DESCRIPTION"
              )[0]?.data ?? null
            }
            ExperienceTabItem={screen.children?.map((i) => i.elements)}
          />
        </TabScreen>
      ))}
    </>
  );
}

export default ExperienceTabs;
